import { useId } from 'react';
import './style.scss';

/**
 * ODS checkbox with label
 * 
 * Usage:
 * - without label: <Checkbox />
 * - with label text: <Checkbox>Label text</Checkbox>
 * - with label element: <Checkbox><span>A styled label</span></Checkbox>
 */

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
}

const Checkbox = (props: CheckboxProps) => {
  const { children, id, className, ...checkboxProps } = props

  const randomId = useId()

  return (
    <div className={`ods-checkbox ${className}`}>
      <div className="ontario-checkboxes__item">
        <input type="checkbox" className="ontario-checkboxes__input" id={id ?? randomId} {...checkboxProps} />
        <label className="ontario-checkboxes__label" htmlFor={id ?? randomId}>
          {children}
        </label>
      </div>
    </div>
  )
}
export default Checkbox