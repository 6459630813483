import React from 'react'
import DateRange from './date-range'
import { toDate, dateLaterThan } from 'utils/date-util'
import { loggerInfo } from 'utils'

const DateFrame = (props: any) => {
  const { style, startDate, endDate, onApply=()=>{}, maxDate, minDate, className,
    format='MMM YYYY', readonly=false, placeHolder='Please input' } = props
  
  const showDate = (ph: any) =><>
    <span aria-hidden='true' className='fa fa-calendar' style={{marginRight: '10px'}}/>
    {
      (!startDate && !endDate) ? 
      <span>{ph}</span>
      :
      !startDate && endDate ?
      (
        (dateLaterThan(new Date(), new Date(endDate)) ? 'Earlier' : 'Present') +  ` - ${toDate(endDate, format)}`
      )
      :
      startDate && !endDate ?
      (
        `${toDate(startDate, format)} - Later`
      )
      :
      <span>{`${toDate(startDate, format)} - ${toDate(endDate, format)}`}</span>
    }
  </>
  
  loggerInfo('dateFrame.render')
  return (
    <span className={`date-frame ${className}`} style={style}>
      {readonly ? 
      showDate('Unspecified') 
      :
      <DateRange menuStyle={{width: '315px'}} onApply={onApply} startDate={startDate} endDate={endDate} format="YYYY-MM-DD" 
        maxDate={maxDate} minDate={minDate}>
        <span>{showDate(placeHolder)}</span>
      </DateRange>
      }
    </span>
  )
}

export default DateFrame