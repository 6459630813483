import { 
  TALENT_SEARCH_REQ_REFRESH,
  TALENT_SEARCH_REQ_CLEAR,
  TALENT_SEARCH_RESULT_REFRESH,
  TALENT_SEARCH_LAST_REQ_REFRESH,
  TALENT_SEARCH_PAGE_NO_REFRESH,
  TALENT_SAVED_QUERIES_REFRESH,
  TALENT_SAVED_QUERY_REFRESH,
  TALENT_RSLT_LISTS_REFRESH,
  TALENT_RSLT_LIST_REFRESH,
  TALENT_RSLT_WORKINGLIST_REFRESH,
  TALENT_RSLT_MEMBER_REFRESH,
  TALENT_RSLT_POSITIONS_REFRESH,
  TALENT_RSLT_LIST_UPDATE_REFRESH,
  TALENT_RSLT_NORMAL_SORT_REFRESH,
  TALENT_RSLT_REMOVED_SORT_REFRESH,
  TALENT_RSLT_FILTER_READINESS_REFRESH,
  TALENT_PAGE_STATUS_REFRESH,
  TALENT_SEARCH_SEARCH_RETURNED,
  TALENT_USER_REFRESH,
  TALENT_USER_CLEAR,
  TALENT_ACCESS_MODE,
  TALENT_RSLT_LIST_EXPAND,
  TALENT_RSLT_SHARE_DETAIL_REFRESH,
  TALENT_RSLT_HISTORY_TOGGLE,
  TALENT_RSLT_NORMAL_TOGGLE,
  TALENT_RSLT_REMOVED_TOGGLE,
  TALENT_RSLT_RESET_TOGGLE,
  TALENT_RSLT_LIST_LOADED_UPDATE_REFRESH,
  TALENT_RSLT_LIST_HISTORY_REFRESH,
  TALENT_RSLT_DETAIL_TOGGLE,
  TALENT_RSLT_ACCESS_CHANGES_REFRESH,
  TALENT_RSLT_OLD_LIST_HISTORY_REFRESH,
  TALENT_RSLT_SELECT_REFRESH,
  TALENT_RSLT_UNSELECT_REFRESH,
  TALENT_RSLT_SELECT_ALL_REFRESH,
  TALENT_RSLT_SELECT_BULK_REFRESH,
  TALENT_RSLT_JUST_ADDED_REFRESH
} from 'services/action-types'
import { TALENT_CHANGE_HISTORY_LIST_PROPERTEIS, TALENT_CHANGE_HISTORY_TALENT_CARD } from 'services/constants'
import { formatDate, getValueByCode, newId, now } from 'utils'

export const initCondition = {
  and: 0,
  conditions: [],
  expand: false,
  parent: ''
}
export const initSearchReq = {
  indId: 0,
  
  profKeywords: initCondition,
  profExtKeywords: initCondition,
  profAlumni: initCondition,
  profIncludePast: 0,
  profJobCategory: initCondition,
  profEmployeeGroup: initCondition,
  profJobLevel: initCondition,   
  profJobClassification: initCondition,  
  profJobTitle: initCondition,      // call api gets vl
  profMinistry: initCondition,      // call api gets vl
  profLocation: initCondition,      // City only

  // profEmployeeGroup: initCondition, 
  
  profSkill: initCondition,         
  profCertificate: initCondition,    
  profLanguage: initCondition,
  profLangCertVerbal: {...initCondition, parent: 'profLanguage'},
  profLangCertWritten: {...initCondition, parent: 'profLanguage'},

  nextRoleJobCategory: initCondition,
  nextRoleEmployeeGroup: initCondition,
  nextRoleJobLevel: initCondition,
  nextRoleJobClassification: initCondition,
  nextRoleLocation: initCondition,  // 
  nextRoleOpportunityType: initCondition,
  nextRoleOpenToOpportunity: initCondition,
  includePhoto: false,
}

export const resultSortValues = [
  {sortDirection: 'DESC', sortBy: 'UPDATE_TIME', sortLabel: 'Profile last updated (newest - oldest)'},
  {sortDirection: 'ASC', sortBy: 'UPDATE_TIME', sortLabel: 'Profile last updated (oldest - newest)'},
  {sortDirection: 'ASC', sortBy: 'NAME', sortLabel: 'Last name (A-Z)'},
  {sortDirection: 'DESC', sortBy: 'NAME', sortLabel: 'Last name (Z-A)'},
  {sortDirection: 'ASC', sortBy: 'FIRST_NAME', sortLabel: 'First name (A-Z)'},
  {sortDirection: 'DESC', sortBy: 'FIRST_NAME', sortLabel: 'First name (Z-A)'}
]
export const listSortValues = [
  // {sortLabel: 'Shortlisted', sortFunc: (a,b)=>b.shortList - a.shortList},
  {sortLabel: 'Profile added (newest - oldest)', sortFunc: (a,b)=>(b.createAt - a.createAt) || a.firstName.localeCompare(b.firstName)},
  {sortLabel: 'Profile added (oldest - newest)', sortFunc: (a,b)=>(a.createAt - b.createAt) || a.firstName.localeCompare(b.firstName)},
  {sortLabel: 'Last name (A-Z)', sortFunc: (a,b)=>a.lastName?.localeCompare(b.lastName)},
  {sortLabel: 'Last name (Z-A)', sortFunc: (a,b)=>b.lastName?.localeCompare(a.lastName)},
  {sortLabel: 'First name (A-Z)', sortFunc: (a,b)=>a.firstName?.localeCompare(b.firstName)},
  {sortLabel: 'First name (Z-A)', sortFunc: (a,b)=>b.firstName?.localeCompare(a.firstName)}
]
export const removedSortValues = [
  {sortLabel: 'Profile removed (newest - oldest)', sortFunc: (a,b)=>b.removeAt - a.removeAt},
  {sortLabel: 'Profile removed (oldest - newest)', sortFunc: (a,b)=>a.removeAt - b.removeAt},
]
const initialState = {
  searchReq: {
    activeTab: 0,
    ...initSearchReq,
    page: 0,
    size: 0,
    ...resultSortValues[0]
  },
  result: {
    totalRecords: 0,
    items: []
  },
  pageStatus: 0,
  savedQueries: [],
  savedQuery: {},
  lists: [],
  list: {
    members:[], 
    positionIds: [], 
  },
  normalSort: listSortValues[0],
  removedSort: removedSortValues[0],
  filterReadiness: [],
  positions: [],
  // listStep: 0,
  listExpand: [],
  listMemberId: null,
  listType: {},
  workingList: {members:[]},
  newPositions: {},
  lastSearchReq: {},
  searchReturned: false, 
  talentUser: {},
  accessMode: {},
  shareDetail: {},
  historyShow: false,
  detailShow: false,
  normalShow: true,
  removedShow: true,
  listHistory: [],
  hasMoreHistory: false,
  historyStartFrom: null,
  oldListHistory: [],
  historyLinkKeys: [],
  accessChanges: [],
  talentSelected: [],
  talentSelectAll: false,
  justAdded: []
}

export const talentReducer =  (state = initialState, action) => {
  switch (action.type) {
  case TALENT_SEARCH_REQ_REFRESH:
    return state = { 
      ...state, 
      searchReq: action.value,
    }
  case TALENT_SEARCH_REQ_CLEAR:
    return state = { 
      ...state, 
      searchReq: {
        ...state.searchReq, 
        ...initSearchReq,
        profAlumni: {...initCondition, conditions: [getValueByCode('RS_LIST_ALUMNI', 'INCLUDE')]},
      },
    }    
  case TALENT_SEARCH_RESULT_REFRESH:
    return state = { 
      ...state, 
      result: action.value,
      listType: {},
    }
  case TALENT_SEARCH_LAST_REQ_REFRESH:
    return state = { 
      ...state, 
      lastSearchReq: action.value
    }
  case TALENT_SEARCH_PAGE_NO_REFRESH:
    return state = {
      ...state,
      searchReq: {
        ...state.searchReq, 
        page: action.value,
      }
    }
  case TALENT_SAVED_QUERIES_REFRESH:
    return state = { 
      ...state, 
      savedQueries: action.value,
      savedQuery: action.value.filter(e=>e.id === state.savedQuery.id)[0] || {}
    }
  case TALENT_SAVED_QUERY_REFRESH:
    return state = { 
      ...state, 
      savedQuery: action.value,
    }
  case TALENT_PAGE_STATUS_REFRESH:
    return state = { 
      ...state, 
      pageStatus: action.value,
    }
  case TALENT_RSLT_LISTS_REFRESH:
    return state = { 
      ...state, 
      lists: action.value,
    }
  case TALENT_RSLT_WORKINGLIST_REFRESH:
    return state = { 
      ...state, 
      workingList: action.value,
    }
  case TALENT_RSLT_LIST_REFRESH:
    const _lists = state.lists
    _lists[_lists.findIndex(e=>e.id === action.value.id)] = action.value
    return state = { 
      ...state, 
      list: action.value,
      listExpand: [...state.listExpand, action.value.type.code], 
      lists: _lists,
      listType: action.value.type,
      listMemberId: action.listMemberId,
      normalSort: listSortValues[0],
      removedSort: removedSortValues[0],
    }
  case TALENT_RSLT_LIST_HISTORY_REFRESH: 
    let ret = buildHistoryTree(action.value.data, [])
    return state = {
      ...state,
      listHistory: ret.tree,
      historyLinkKeys: ret.linkKeys,
      hasMoreHistory: action.value.hasMoreData,
      historyStartFrom: action.value.startFrom
    }
  case TALENT_RSLT_OLD_LIST_HISTORY_REFRESH: 
    ret = buildHistoryTree(action.value, state.historyLinkKeys)
    return state = {
      ...state,
      oldListHistory: ret.tree,
      historyLinkKeys: ret.linkKeys,
    }
  case TALENT_RSLT_MEMBER_REFRESH:
    const _members = state.list.members
    const index = _members.findIndex(e=>e.id === action.value.id)
    _members[index] = action.value
    return state = { 
      ...state, 
      list: {...state.list, members: _members}
    }
  case TALENT_RSLT_POSITIONS_REFRESH:
    return state = { 
      ...state,
      positions: state.list.linkedPos.map((e,i)=>({positionNum: e.posId, ...e, ...action.value[i]})).sort((a,b)=>b.createAt-a.createAt)
    }
  case TALENT_RSLT_LIST_UPDATE_REFRESH:
    return state = { 
      ...state, 
      list: {...state.list, lastUpdateAt: action.value.lastUpdateAt}
    }
  case TALENT_RSLT_LIST_LOADED_UPDATE_REFRESH:
      return state = { 
        ...state, 
        lists: state.lists.map(e => e.id === action.value.id ? {...e, loadedAt: now()} : e)
      }
  case TALENT_RSLT_NORMAL_SORT_REFRESH:
    return state = { 
      ...state,
      normalSort: action.value,
      listMemberId: null,
    }
  case TALENT_RSLT_REMOVED_SORT_REFRESH:
    return state = { 
      ...state,
      removedSort: action.value,
      listMemberId: null,
    }
  case TALENT_RSLT_FILTER_READINESS_REFRESH:
    return state = { 
      ...state,
      filterReadiness: action.value,
    }
  case TALENT_SEARCH_SEARCH_RETURNED:
    return state = { 
      ...state, 
      searchReturned: action.value,
    }
  case TALENT_USER_REFRESH:
    return state = { 
      ...state, 
      talentUser: {...state.talentUser, ...action.value},
    }
  case TALENT_USER_CLEAR:
    return state = { 
      ...state, 
      talentUser: {},
    }
  case TALENT_ACCESS_MODE:
    return state = { 
      ...state, 
      accessMode: action.value
    }
  case TALENT_RSLT_LIST_EXPAND:
    return state = { 
      ...state, 
      listExpand: action.value
    }
  case TALENT_RSLT_SHARE_DETAIL_REFRESH:
    return state = { 
      ...state, 
      shareDetail: action.value
    }
  case TALENT_RSLT_HISTORY_TOGGLE:
    return state = { 
      ...state, 
      historyShow: action.value
    }
  case TALENT_RSLT_DETAIL_TOGGLE:
    return state = { 
      ...state, 
      detailShow: action.value
    }
  case TALENT_RSLT_NORMAL_TOGGLE:
    return state = { 
      ...state, 
      normalShow: action.value || !state.normalShow
    }
  case TALENT_RSLT_REMOVED_TOGGLE:
    return state = { 
      ...state, 
      removedShow: action.value || !state.removedShow
    }
  case TALENT_RSLT_RESET_TOGGLE:
    return state = { 
      ...state, 
      normalShow: action.value,
      removedShow: action.value
    }    
  case TALENT_RSLT_ACCESS_CHANGES_REFRESH:
    return state = { 
      ...state, 
      accessChanges: action.value
    }
  case TALENT_RSLT_SELECT_REFRESH:
    return state = { 
      ...state, 
      talentSelected: [...state.talentSelected, action.value],
      talentSelectAll: state.talentSelected.length === state.result.items.length -1
    }
  case TALENT_RSLT_UNSELECT_REFRESH:
    return state = { 
      ...state, 
      talentSelected: state.talentSelected.filter(t=>t.indId !== action.value.indId),
      talentSelectAll: false
    }
  case TALENT_RSLT_SELECT_ALL_REFRESH:
    return state = { 
      ...state, 
      talentSelectAll: action.value,
      talentSelected: action.value ? state.result.items : [],
    }
  case TALENT_RSLT_SELECT_BULK_REFRESH:
    return state = { 
      ...state, 
      talentSelected: action.value,
      talentSelectAll: action.value.length === state.result.items.length
    }
  case TALENT_RSLT_JUST_ADDED_REFRESH:
    return state = { 
      ...state, 
      justAdded: action.value ? [...state.justAdded, ...action.value] : []
    }
  default:
      return state
  }
}

const buildHistoryTree = (history, historyLinkKeys) => {
  let tree = []
  let linkKeys = [...historyLinkKeys]
  history.forEach(item => {
    let actionMonth = formatDate(new Date(item.actionAt), 'MMM YYYY')
    let monthItem = tree.find(t => t.label === actionMonth)
    if (!monthItem) {
      let types = [
        {id: newId(), label: TALENT_CHANGE_HISTORY_LIST_PROPERTEIS, level: 2, children: []},
        {id: newId(), label: TALENT_CHANGE_HISTORY_TALENT_CARD, level: 2, children: []}
      ]
      tree.push({
        id: newId(),
        label: actionMonth,
        level: 1,
        children: types
      })
    }

    let lbl = item.changeType!=='LIST_MEMBER' ? TALENT_CHANGE_HISTORY_LIST_PROPERTEIS : TALENT_CHANGE_HISTORY_TALENT_CARD

    // linkable check, for the same field, only 1st one in history can be linked
    if (item.changeType === 'LIST' && item.changeData.action==='UPDATE') {
      item.changeData.updateFields.forEach(e => {
        e.showLink = isItemLinkable(item, e, linkKeys)
      })
    } else {
      item.changeData.showLink = isItemLinkable(item, item.changeData, linkKeys)
    }
    tree.find(t => t.label === actionMonth).children.find(e => e.label===lbl).children.push(item)
  })
  return {tree: tree, linkKeys: linkKeys}
}

const isItemLinkable = (item, field, historyLinkKeys) => {
  if (field.linkable === 2) return true
  if (field.linkable === 0) return false
  let key = `${item.changeType}/${field.type}/${field.fieldName ? field.fieldName : ''}/${item.listMemberId ? item.listMemberId : ''}`
  if (historyLinkKeys.includes(key)) {
    return false
  } else {
    historyLinkKeys.push(key)
    return true
  }
}