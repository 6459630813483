import { join } from './text-util'

export const displayUserName =user =>{
  if(!user){
     return ''
  }else if(user.firstName || user.lastName){
    return join([user.firstName, user.lastName], ' ') 
  } else{
    return (user.displayName||user.userFullName||user.fullName||user.email||'').replace(',', '')
  }
}

export const connectCalss = (jl, jc) => {
  let ret = ''
  let _jc = jc ? '(' + jc + ')' : ''
  if(jl){
    ret = `${jl} ${_jc}`
  }else if(jc){
    ret = _jc
  }
  return ret
}