import React, { useState, forwardRef, useImperativeHandle } from 'react'
//@ts-ignore
import Pagination from 'react-js-pagination'
import './paginate.scss'

export default forwardRef((props: any, ref) => {
  const { children, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, onPage=()=>{} } = props
  const [ activePage, setActivePage ] = useState(1)

  const api = () => ({
    reset: (pNo: any) => {
      setActivePage(pNo)
    }
  })
  useImperativeHandle(ref, api)

  const onChange = (pageNo: any) => {
    setActivePage(pageNo)
    onPage(pageNo)
  }
  const PaginationWrapper = ()=> <Pagination
    prevPageText='&#129120;Previous'
    nextPageText='Next&#129122;'
    firstPageText='First'
    lastPageText='Last'
    activePage={activePage}
    itemsCountPerPage={itemsCountPerPage}
    totalItemsCount={totalItemsCount}
    pageRangeDisplayed={pageRangeDisplayed}
    onChange={onChange}
    disabledClass='disabled'
    hideDisabled={true}
  />
  return <div className='paginate'>
    {/* <PaginationWrapper/> */}
    {children}
    <h3 className="sr-only">press tab key to select page</h3>
    <PaginationWrapper/>
  </div>
})