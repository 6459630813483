import { forwardRef, useImperativeHandle, useEffect, useRef} from 'react'
import './dropdown-form.scss'
import { focusOnSelectors2 } from 'utils'

export default forwardRef((props: any, ref) =>  {
	const { children, display, menuStyle, onShow=()=>{}, onHide=()=>{} }=props
	const refDropdown: any = useRef(), refForm: any = useRef()

	useEffect(() => {		
		const cb_show = () => {
			setTimeout(()=>focusOnSelectors2(refForm, [
				"input, [href], select, textarea, [tabindex]:not([tabindex='-1']",
				"button.tms-button:not([disabled]), button.flat-button"
			]))
			onShow()
		}
		const cb_hide = (event : any) => {
			const tgl = refDropdown.current.querySelector(':scope > .dropdown-toggle')
			if (tgl === event.relatedTarget) {
				tgl.focus()
			}
			onHide()
		}
		refDropdown.current.addEventListener('show.bs.dropdown', cb_show)
		refDropdown.current.addEventListener('hide.bs.dropdown', cb_hide)
		return ()=>{
			refDropdown.current?.removeEventListener('show.bs.dropdown', cb_show)
			refDropdown.current?.removeEventListener('hide.bs.dropdown', cb_hide)
		}
	}, [])
	
	const api = () => ({
	// 	showDropMenu: (e: any) => wrapperRef.current.showDropMenu(e),
		hideDropMenu: (e: any) => refDropdown.current.querySelector(':scope > .dropdown-toggle').click(),
	// 	isToggleOn: () => wrapperRef.current.isToggleOn()
	})
	useImperativeHandle(ref, api)
	
	return (<div ref={refDropdown} className='dropdown dropdown-form'>
		<button type='button' className='ontario-button ontario-button--secondary dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false' data-bs-auto-close='outside'>
			{display}
		</button>
		<div ref={refForm} className='dropdown-menu' style={{...menuStyle}}>
			{children}
		</div>
		</div>
	)
})