import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { Modal } from 'components/common'
import { newId } from 'utils'
import Unavailable from './unavailable'
import Someone from './someone'
import Relogin from './relogin'
import { MentorMsgCode } from 'types'
import './style.scss'

class propsType{
  onClose?: any;
}
export const MentorServicePops = forwardRef((props: propsType, ref) => {
  // const { onClose } = props
  const modalRef: any = useRef({})
  const [ step, setStep ] = useState<MentorMsgCode>(MentorMsgCode.init)

  useImperativeHandle(ref, () => ({
		show(content: any){
      setStep(content.msgCode)
      modalRef.current.show()
    },
    hide(){
      modalRef.current.hide()
    }
  }))

  const onCancel = () => modalRef.current.hide()

  const onHide = () => {
  }
  const aodaProps = {describedBy: newId(), labelledBy: newId()}
  const stepProps = { ...aodaProps}
  return (<div className='mentor-service-pops'>
    <Modal onHide={onHide} ref={modalRef} {...aodaProps} className={`mentor-service-pops-modal step_${step}`}>
      {
      step === MentorMsgCode.EC001 ?
      <Unavailable {...stepProps} onClose={()=>onCancel()}/>
      :
      step ===  MentorMsgCode.EM1000 ?
      <Relogin {...stepProps} onClose={()=>onCancel()}/>
      :
      step === MentorMsgCode.EM0003 || MentorMsgCode.VM3008 || MentorMsgCode.VM3007?
      <Someone {...stepProps} onClose={()=>onCancel()}/>
      :
      <span/>
      }
    </Modal>
  </div>)
})