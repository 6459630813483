import { ValueListDto, CustomDetailJson, SortDirection, UserBaseDto, UserBase } from 'types'

interface MentorTagDto{
    id: number;
    ver: number; //Update_At time
    profId: number;
    value: ValueListDto;
    displayOrder: number;
}

export class MentorDetailDto{
    id!: number;
    ver!: number;        //Update_At time
    profId!: number;
    status!: number;     //Hidden, Draft, publish
    type!: ValueListDto;
    cate!: ValueListDto;
    value!: ValueListDto;
    other!: CustomDetailJson;
    displayOrder!: number;
}
export class MentorSearchCondition{
    [key: string]: MentorSearchCondition[keyof MentorSearchCondition];
    and: number = 0; //0: Or, 1: And
    conditions: ValueListDto[] = [];
    expand: boolean = false;    //UI Only
}
enum ValueListMentorSearch{ //MT_SEARCH
    PAGE_SIZE = 20,
    KEYWORDS_SIZE = 5,
    DEF_LIST_SIZE = 50,
    EMAIL_LIST_SIZE = 2000
}
enum MTConstants{
    TRUE = 1,
    FALSE = 0,
    default_audit_user = '0',//SYSTEM
    ACT_BY_SELF = 0,
    ACT_BY_CORP_ADMIN = 1,
    ACT_BY_MIN_ADMIN = 2,

    //Roles
    ROLE_SYS_ADMIN = 'SYS_ADMIN',
    ROLE_CORP_ADMIN = 'CORP_ADMIN',
    ROLE_MIN_ADMIN = 'MIN_ADMIN',

    //----------------- Search ------------------------
    myBatis_ms_first_name = 'coalesce(ind.first_name, im.win_first_name)',

    //----------------- cache -------------------------
    CACHE_VALUE_LIST_MAP = 'tmValueList',
    CACHE_INDIVIDUAL_MAP= 'tmIndividual'
}
export enum MentorSearchSortBy{
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    MATCH_SKILLS = 'MATCH_SKILLS',
    SPOT_NUM = 'SPOT_NUM',
}
export class MentorSearchCriteriaJson{
    [key: string]: MentorSearchCriteriaJson[keyof MentorSearchCriteriaJson];
    name!: string;
    myProfId!: number;
    
    profKeywords: MentorSearchCondition = new MentorSearchCondition();
    profExtKeywords: MentorSearchCondition = new MentorSearchCondition();

    profSkill: MentorSearchCondition = new MentorSearchCondition();
    profJobCategory: MentorSearchCondition = new MentorSearchCondition();
    profJobLevel: MentorSearchCondition = new MentorSearchCondition();
    profLocation: MentorSearchCondition = new MentorSearchCondition(); // City only
    profMinistry: MentorSearchCondition = new MentorSearchCondition();
    profLanguage: MentorSearchCondition = new MentorSearchCondition();
    profEmployeeNetwork: MentorSearchCondition = new MentorSearchCondition();
    profMeetingPreference: MentorSearchCondition = new MentorSearchCondition();

    page: number = 0; // Start from 0;
    size: number = ValueListMentorSearch.PAGE_SIZE;
    sortDirection: SortDirection = SortDirection.DESC;
    sortBy: MentorSearchSortBy = MentorSearchSortBy.FIRST_NAME;
    sortLabel: string = '' // UI Only
}
export class MentorSearchCriteriaDto{
    // [key: string]: MentorSearchCriteriaDto[keyof MentorSearchCriteriaDto];
    id!: number;
    ver!: number;
    profId!: number;
    active: number = 1;
    name!: string;
    comment!: string; //description
    owner!: UserBaseDto;
    type!: ValueListDto;
    condition!: MentorSearchCriteriaJson;
}

export class MentorProfileDto{
    [key: string]: MentorProfileDto[keyof MentorProfileDto];
    id!: number;
    ver!: number; //Update_At time
	user!: UserBaseDto;
    type!: ValueListDto;
    status!: number;
    manualToggle!: number;
    nextStep!: number;
    topSkills: ValueListDto[] = []; //For UI show only
    maxPeerNum: number = 1;
    mentorSpot: number = 0;
    goals!: string;
    searchCriteria: MentorSearchCriteriaDto[] = [];
    // pronouns!: string;
    pronouns: MentorDetailDto[] = [];
    locations: MentorDetailDto[] = [];
    languages: MentorDetailDto[] = [];
    learnThrough: MentorDetailDto[] = [];
    employeeNetwork: MentorDetailDto[] = [];
    personalInterests: MentorDetailDto[] = [];
    meetingPreferChannel: MentorDetailDto[] = [];
    meetingPreferOften: MentorDetailDto[] = [];
    meetingPreferLong: MentorDetailDto[] = [];
    meetingPreferDuration: MentorDetailDto[] = [];
    acknowledges: MentorTagDto[] = [];
    skills: MentorDetailDto[] = [];
    expSkills: MentorDetailDto[] = [];
}

export class MentorshipCardDto{
	//individual
    indId!: number;
    ind!: UserBase;
    spots!: number;
    noMoreRequest!: number;
	//Relationship
    mentorshipId!: number;
    initProfId!: number;  //login profId same as initProfId show cancel, diff show accept/reject
    mentorshipType!: ValueListDto;  //NOTIFY: UI notification, SAVED: saved list, MENTORSHIP: category by mentorshipStatus: PENDING, ACCEPT, DECLINE, CANCEL, COMPLETE
    mentorshipStatus!: ValueListDto;
    statusByProfId!: number  //login profId same as statusByProfId show cancel by , diff show accept/reject by
    lastUpdateAt!: number;  //completeAt, CancelAt...

    //Event Dto
	events!: MentorshipEventDto; 

	//Search result
    profId!: number; //card's profile id
    profActive!: number;
    activeInWin!: number;
    profType!: ValueListDto;
    profUUID!: string;  //For Mybatis
    inSavedList!: number; //1: true, 0: false
    inSavedListTime!: number;
    details: MentorDetailDto[] = [];
    matchedSkill: MentorDetailDto[] = [];
    userMentorProfId!: number; //UI Only
    shared!: boolean;
    uuid!: string;
}
export class MentorshipSearchResultDto {
    totalRecords!: number;
    items: MentorshipCardDto[] = [];
}
export class MentorAutoCompleteReq{
    type!: ValueListDto;
    input!: string;
    mentorScope: number = 1;
}
export class MentorshipEventDto {
    id: number=0;
    ver: number=0; //Update_At time
    mentorshipId: number=0;
    type!: ValueListDto; //pending, cancel, decline, accept, complete, archive
    mentorProfId: number=0;
    menteeProfId: number=0;
    initByProfId: number=0;
    eventByProfId: number=0;
    eventBy!: UserBase;
    eventAt: number=0;
    comment!: string;
}
export class ProfStatusCountDto{
    profId: number=0;
    status!: ValueListDto;
    count: number=0;
}
export class ProfQualifyReqDto{
    fromProfId: number=0;
    toProfId: number=0;
    sendRequest: number = 0;
    reason!: string;
}
export enum MentorshipType{
    SEARCH = 'SEARCH',  //UI Only
    MENTORSHIP = 'MENTORSHIP',
    SAVED =  'SAVED',
    NOTIFY = 'NOTIFY'
}

export enum MentorshipStatus{
    SEARCH = 'SEARCH',  //UI Only
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    ACCEPTED = 'ACCEPTED',
    COMPLETED = 'COMPLETED',
    SAVED = 'SAVED',
    NOTIFY = 'NOTIFY',  //UI Only
}

export enum MentorMsgCode{
    init = 'init',      // UI Only
    EC001 = 'EC001',    // Mentor service unavailable
    EM1000 = 'EM1000',  // profile is not ready
    EM0003 = 'EM0003',  // Concurrence issue
    VM3002 = 'VM3002',  // Save dup
    EM3005 = 'EM3005',  // Save limit
    VM1004 = 'VM1004',  // You can't turn the toggle
    VM1005 = 'VM1005',  // The maximum peer number cannot less than existing pending requests
    VM3003 = 'VM3003',  // No spots 
    VM3007 = 'VM3007',  // No spots 
    VM3008 = 'VM3008',  // Concurrence with request/decline/cancel/accept/complete
}