import { useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import { Key, newId } from 'utils'
import ResizeDetector from '../resize-detector'
import Scrollbar from '../scroll-bar'
import './dropdown.scss'

export default forwardRef((props: any, ref) =>  {
  const { 
    className, style, menuClassName, menuStyle, id, role="button", menuRole="menu", focusable=false, labelledBy, menuLabelledBy, menuDescribedBy, menuLabel='dropdown',
    display, children, scrollable=false, pullRight = false, pullUp = false, nextFocus, ariaDescription, 
    menuHeader,
    onDropMenu=()=>{},
    onKeyDown=()=>{},
    onBlur=()=>{},
    onClick=()=>{},
    focusOnFirst=()=>{},
    clear=()=>{}
  }=props
  
  const [toggleOn, setToggleOn] = useState(false)
  const [menuHeight, setMenuHeight] = useState(0)
  const [activeElement, setActiveElement] = useState<any>({})
  const [menuId, setMenuId] = useState('')
  const wrapperRef: any = useRef({})
  const menuRef: any = useRef({})

  useEffect(()=> {
    setMenuId(!!id? id: newId())
  }, [id])

  const handleClickOutside = (event: any) => blurDropMenu(event.target)

  const handleKeypress = (event: any) => {
    if(event.keyCode === Key.ESCAPE){
      onHideDropMenu()
    }else if(event.keyCode === Key.TAB){
      setTimeout(()=>blurDropMenu(document.activeElement), 100)
    }
  }

  const blurDropMenu = (target: any) => {
    if (!wrapperRef || !wrapperRef.current || menuRef.current.contains(target)) return
    onHideDropMenu(false)
  }

  const api = () => ({
    showDropMenu: () => onShowDropMenu(),
    hideDropMenu: onHideDropMenu,
    contains: (e: any) => {return (wrapperRef.current.contains(e))},
    isToggleOn: () => toggleOn,
    focusOnFirst: () => focusOnFirst(menuRef),
    clear: () => clear(menuRef),
    click: () => wrapperRef.current.querySelector('.dropdown-display').click()
  })
  useImperativeHandle(ref, api)
  
  const onShowDropMenu = () =>{
    setActiveElement(document.activeElement)
    setToggleOn(true)
    onDropMenu(true)
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keyup', handleKeypress)
  }

  const onHideDropMenu = (restoreFocus = true, moveNext?:boolean, event?: any) =>{
    setToggleOn(false)
    onDropMenu(false, event)
    if (restoreFocus) {
      setTimeout(() => {
        if (moveNext && nextFocus) {
          let next = document.querySelector(nextFocus) 
          next && next.focus()
        } else {
          activeElement.focus && activeElement.focus()
        }
      })
    } 
    document.removeEventListener('mousedown', handleClickOutside)
    document.removeEventListener('keyup', handleKeypress)
  }

  const onResize = (w: number, h: number) => setMenuHeight(h + 27)

  return (
    <div className={`custom-dropdown ${className} ${pullRight && 'pull-down-right'}`}
    ref={wrapperRef} style={{...style}} onClick={onClick} onBlur={onBlur} onKeyDown={onKeyDown}>
      <span className={`dropdown ${scrollable ? 'scrollable' : ''}`} >
        {
        !focusable ? 
          <div id={`${menuId}_btn`} data-bs-toggle="dropdown" className='dropdown-display' tabIndex={0} {...(ariaDescription ? {'aria-description':ariaDescription} : {'aria-description':'Activate dropdown menu and use tab key to navigate'})} 
            role={role} aria-haspopup={menuRole} aria-controls={menuId} aria-expanded={toggleOn} {...(menuDescribedBy ? {'aria-describedby':menuDescribedBy}:'')}
            {...(labelledBy ? {'aria-labelledby': labelledBy} : {'aria-labelledby': `${!!menuLabelledBy ? menuLabelledBy + ' ': ''}${menuId}_btn`})}>
            { display }
          </div>
          :
          <span className='simple-row dropdown-display' data-bs-toggle="dropdown">{display}</span>
        }
        <div ref={menuRef} id={menuId} role={menuRole} {...(menuLabelledBy ? {'aria-labelledby': menuLabelledBy}:{'aria-label':menuLabel})}
          className={`dropdown-menu ${menuClassName} ${toggleOn && 'show'}  ${pullUp && 'pull-up'}`} 
          style={scrollable ? {...menuStyle, height: `${menuHeight}px`}: menuStyle}>
          
          { !scrollable ? children :
            <Scrollbar><div style={{display: 'flex', flexDirection: 'column'}}>
              { menuHeader ? <div className='simple-row'>{menuHeader}</div> : <></> }
              { children }
              <ResizeDetector onResize={onResize}/>
              </div>
            </Scrollbar>
          }
        </div>
    </span>
    </div>
  )
})