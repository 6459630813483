import { Key } from './key-util'
let activeElement = {}
let focusSelector = ''
export const jumpFocus = (_focus, drillDown=false, inModal=false) => {
    activeElement = document.activeElement
    if(focusSelector && document.querySelector(focusSelector)){
        document.querySelector(focusSelector).removeEventListener('keydown', restoreLastFocus)
    }

    if (!drillDown) {
        focusSelector = _focus
        if(focusSelector && document.querySelector(focusSelector)){
            document.querySelector(focusSelector).focus()    
            document.querySelector(focusSelector).addEventListener('keydown', restoreLastFocus)
        }
    } else {
        let element = document.querySelector(_focus)
        if (element) {
            let firstFocus = focusOnFirst(element, inModal)
            firstFocus && firstFocus.addEventListener('keydown', restoreLastFocus)
        }
    }

    function restoreLastFocus(event) {
        if(event.shiftKey && event.keyCode === Key.TAB && document.activeElement.className.indexOf(focusSelector.replace('.', '')) > -1) {
            event.preventDefault() 
            activeElement.focus()
        }
    }
}

export const jumpFocus2 = _focus => {
    if(!_focus){
        return
    }

    if(Array.isArray(_focus)){
        document.querySelector(_focus.filter(f=>document.querySelector(f))[0]).focus()
    }else if(document.querySelector(_focus)){
        document.querySelector(_focus).focus()
    }
}

export const registerJumpFocus = _focus => {
    window.addEventListener('focus', ()=>jumpFocus2(_focus));
}
export const unregisterJumpFocus = _focus => {
    window.removeEventListener('focus', ()=>jumpFocus2(_focus));
}

export const navigateList = (itemRef, event) => {
    if (event.keyCode === Key.DOWN_ARROW) {       // Down
        event.preventDefault()
        event.stopPropagation()
        const i = itemRef.current.indexOf(document.activeElement)
        if(i < itemRef.current.length -1){
            itemRef.current[i + 1].focus()
        }
    } else if (event.keyCode === Key.UP_ARROW ) { // Up
        event.preventDefault()
        event.stopPropagation()
        const i = itemRef.current.indexOf(document.activeElement)
        if(i > 0 ){
            itemRef.current[i - 1].focus()
        }
    } 
}

export const navigateList2 = e => {
    if (e.keyCode === Key.DOWN_ARROW) {   // Down
        e.preventDefault()
        e.stopPropagation()
        e.target.nextElementSibling && e.target.nextElementSibling.focus()
    }else if (e.keyCode === Key.UP_ARROW) { // Up
        e.preventDefault()
        e.stopPropagation()
        e.target.previousElementSibling && e.target.previousElementSibling.focus()
    }
}

export const navigateShowMoreList = e => {
    if (e.keyCode === Key.DOWN_ARROW) {   // Down
        e.preventDefault()
        e.stopPropagation()
      
        if (e.target.nextElementSibling) {
            if (e.target.nextElementSibling.className.indexOf('show-more') > -1) {
                let items = e.target.nextElementSibling.childNodes
                items.length > 0 && items[0].focus()
            } else {
                e.target.nextElementSibling.focus()
            }
        }
    }else if (e.keyCode === Key.UP_ARROW) { // Up
        e.preventDefault()
        e.stopPropagation()
        if (e.target.previousElementSibling) {
            e.target.previousElementSibling.focus()
        } else {
            let showMore = e.target.closest(".show-more")
            if (showMore && showMore.previousElementSibling) {
                showMore.previousElementSibling.focus()
            }
        }
    }
}

export const onListNavigate1 = ({itemRef, nextFocus, escFocus, event, drillDown=false, inModal=false}) => {
    navigateList(itemRef, event)

    if (event.keyCode === Key.TAB && !event.shiftKey && nextFocus) { // Tab
        event.preventDefault()
        jumpFocus(nextFocus, drillDown, inModal)
    } else if (event.keyCode === Key.TAB && event.shiftKey && escFocus) { // Shift + Tab
        event.preventDefault()
        jumpFocus2(escFocus)
    } else if (event.keyCode === Key.RIGHT_ARROW && nextFocus) { //Right arrow to move to the right side
        event.preventDefault()
        jumpFocus(nextFocus, drillDown, inModal)
    }
  }
export const onListNavigate = ({itemRef, nextFocus, escFocus, event, action, drillDown=false}) => {
    const _item = itemRef.current.filter(e=>e === event.target)[0]
    const _item1 = itemRef.current.filter(e=>e.contains(event.target))[0]
    const _item2 = _item1 && _item1.querySelector(action)
    const _item3 = _item1 && _item1.querySelector('.dropdown-menu.show')
    const _item4 = (_item1 && _item1.querySelectorAll('.dropdown-item'))||[]
    const _item5 = _item4[_item4.length - 1]  
    
    // console.log("key code: => ", event.keyCode)
    navigateList(itemRef, event)
    if (event.keyCode === Key.TAB && event.shiftKey && _item){
        onListNavigate1({itemRef, nextFocus, escFocus, event})
    }

    if (event.keyCode === Key.TAB && !event.shiftKey){
        if(!_item3 && event.target ===_item2){
            onListNavigate1({itemRef, nextFocus, escFocus, event})
        }else if(event.target ===_item5){
            _item1.focus()
            onListNavigate1({itemRef, nextFocus, escFocus, event})
        }else if((_item2||_item3) && event.target !==_item5){
            // do nothing.
        }else{
            onListNavigate1({itemRef, nextFocus, escFocus, event, drillDown})
        }
    }

    if (event.keyCode===Key.RIGHT_ARROW){
        if(!_item3 && event.target ===_item2){
            onListNavigate1({itemRef, nextFocus, escFocus, event})
        }else if(event.target ===_item5){
            _item1.focus()
            onListNavigate1({itemRef, nextFocus, escFocus, event})
        }else if((_item2||_item3) && event.target !==_item5){
            // do nothing.
        }else{
            onListNavigate1({itemRef, nextFocus, escFocus, event, drillDown})
        }
    }
}

export const addToRefList = (itemRef, el) => {
    if (el && itemRef.current.indexOf(el) < 0) {
        itemRef.current.push(el)
    }
    return el
}

export const emptyRefList =(itemRef) => {
    while (itemRef.current.length) { 
        itemRef.current.pop(); 
    }
}

export const navigateWithAction = (event, item, itemRef, actionClass, containerClass, nextFocus) => {
    if (event.target.className.indexOf(actionClass) > -1) {
      if (!event.shiftKey) { 
        let ddl = event.target.closest(".dropdown").querySelector(".dropdown-menu")
        if (!ddl || ddl.className.indexOf("show") < 0) {
          event.preventDefault()
          event.stopPropagation()
          itemRef.current = item
          jumpFocus(nextFocus)
        }
      } else {
        event.preventDefault()
        event.stopPropagation()
        event.target.closest(`.${containerClass}`).focus()
      }
      return false
    } else if (!event.shiftKey && event.target.className.indexOf(containerClass) > -1){
      event.preventDefault()
      event.stopPropagation()
      let actionElement = event.target.closest(`.${containerClass}`).querySelector(`.${actionClass}`)
      if (!!actionElement) actionElement.focus()
      return true
    }  
}
export const focusClosest = function (elem, selector) {
    let fc = null
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        fc = elem.querySelector(selector)
        if(fc){
            fc.focus()
            return
        }
    }
}
export const findParent= function (elem, selector) {
    let parent = {}
    let _elem = elem.parentNode
    for ( ; _elem && _elem !== document; _elem = _elem.parentNode ) {
        parent = _elem.querySelector(selector)
        if(parent && elem !== parent && parent.contains(elem)){
            return parent
        }
    }
    return null
}
export const focusOnSelectors = (ref, selectors, time=200) => setTimeout(() => { 
    selectors.map(selector => {
        const item = ref?.current?.querySelectorAll(selector)[0]
        item && item.focus()
        return selector
    }) 
}, time)

export const focusOnRefs = refs => setTimeout(() => { 
    refs.map(ref => ref?.current && ref?.current.focus && ref?.current.focus()) 
})

export const focusOnSelectors2 = (ref, selectors) => { 
    for (let i = 0; i < selectors.length; i++) {
        let focusables = ref?.current?.querySelectorAll(selectors[i]) || []
        if (focusables.length > 0) { 
            focusables[0].focus(); 
            return focusables[0]
        }
    }
}

export const focusOnFirst = (element, inModal=false) => {
    let focusables=element?.querySelectorAll('button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"]') || []
    if (focusables.length > 0) {
        for (let i = 0; i < focusables.length; i++) {
            if (!inModal && focusables[i].closest(".e-modal-dialog")) {
                continue
            }
            focusables[i].focus()
            return focusables[i]
        }
    }
}

export const focusOnNext = ref => {
    // event.target.closest(`.${containerClass}`)
}

export const refCall = (ref, func, params) => {
    const _func = ref?.current && ref?.current[func]
    return _func && _func(params)
}

export const isScrolledIntoView = el => {
    var rect = el.getBoundingClientRect()
    var elemTop = rect.top
    var elemBottom = rect.bottom

    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight)
    return isVisible
}