import { createSelector } from 'reselect'
export const getNavUser = createSelector(
  (state: any) => state.ui.nav.user, 
  data => ({ ...data, userPK: (data.userPK - 0) || data.indId, id: data.userPK - 0 })
)
export const getEnv = createSelector(
  (state: any) => state.ui.nav.env, 
  data => data
)
export const getNavId = createSelector(
  (state: any) => state.ui.nav.id, 
  data => data
)
export const getSkipTo = createSelector(
  (state:any) => state.ui.nav.skipTo, 
  data => data
)