import { useEffect, useRef, useState} from 'react'
import { DropDownForm, OdsButton, Checkbox } from 'components/common'
import { ValueListDto } from 'types'
import './style.scss'

class propsType{
  header?: any;
  children?: any; 
  selected: any[] = []; 
  options: any[] = [];
  onApply: any;
  describedBy?: string;
  menuStyle: any;
}
const CriteriaChecks = (props: propsType) =>{
  const { header, children, selected, options, onApply, describedBy} = props
  const [values, setValues] = useState<any>([])
  const refForm: any = useRef({})

  useEffect(()=>{
    setValues([...selected])
  }, [selected])
  
  const onClear = () => {
    setValues([])
  }
  const handleApply = () => {
    onApply(values)
    refForm.current.hideDropMenu()
  }
  const handleChange = (event: any, e: any) => {
    if(event.target.checked){
      setValues([...values, e])
    }else{
      setValues(values.filter((s:any)=>s.code !==e.code))
    }
  }
  return (<div className='criteria-selects'>
    <DropDownForm ref={refForm} menuRole="dialog"
      display={<span className='display'>{children}</span>}>
      <div className='simple-row selects-header'>{header}</div>
      {options.map((e: ValueListDto, i: number)=><div key={i} className='simple-row check-item'>
        <Checkbox label={e.value} 
          describedBy={describedBy} 
          display={e.label} 
          checked={values.some((s:any)=>s.code === e.code)} 
          onChange={(evt: any)=>handleChange(evt, e)}/>
      </div>)}
      <div className='simple-row selects-footer'>
        <OdsButton level={'tertiary'} onClick={onClear}>Clear</OdsButton>
        <OdsButton level={'primary'} onClick={handleApply}>Apply</OdsButton>
      </div>
    </DropDownForm>
  </div>)
}
export default CriteriaChecks