import './join.scss'

 const Join = (props: any) =>{
  const { children, by, className } = props
  const _children = children.filter((e: any)=>e)
  const fasts = _children.slice(0, -1)
  const last = _children[_children.length - 1]

  return (
    <span className={`join ${className}`}>{fasts.map((e: any, i: number)=><span key={i.toString()}>{e}{by}</span>)}{last}</span>
  )
}
export default Join