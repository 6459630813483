import { useEffect, useRef } from 'react'
import './style.scss'

interface propsType{
	id?: any;
	children?: any;
	display?: any;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	className?: string;
	menuLabel?: string;
	menuRole?: string;
 	menuLabelledBy?: string;
}

const OdsDropdown = (props: propsType) => {
	const { id, display='Select', children, onClick, className, menuLabel, menuRole, menuLabelledBy } = props
	const refDropdown: any = useRef({})

	useEffect(() => {
		const cb_show = () => {
			// console.log('show')
		}
		const cb_hide = () => {
			refDropdown.current.querySelector('.dropdown-toggler').focus()
		}
		refDropdown.current.addEventListener('show.bs.dropdown', cb_show)
		refDropdown.current.addEventListener('hide.bs.dropdown', cb_hide)
		return ()=>{
			refDropdown.current?.removeEventListener('show.bs.dropdown', cb_show)
			refDropdown.current?.removeEventListener('hide.bs.dropdown', cb_hide)
		}
	}, [])
	
	const menuAodaProps = {role: menuRole, ...(menuLabelledBy ? {'aria-labelledby': menuLabelledBy}:{'aria-label':menuLabel})}
	return (<div ref={refDropdown} className={`ods-dropdown btn-group ${className}`}>
	<button id={id} type='button' className='ontario-button dropdown-toggler' aria-haspopup='menu' data-bs-toggle='dropdown' aria-expanded='false'
		onClick={onClick} >
		<span {...menuAodaProps}>{display}</span><span aria-hidden='true' className='fa fa-caret-down'></span>
	</button>
	<ul className={`dropdown-menu${!children || children.length === 0 ? ' d-none' : ''}`}>
		{children}
	</ul>	
</div>)
}
export default OdsDropdown