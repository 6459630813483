import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import Draggable from 'react-draggable'
import { loggerInfo, Key, newId, focusOnSelectors2, findParent} from 'utils'
import { Button } from './elements'
import './modal.scss'

const wrapped = [
  'button',
  `div[tabindex='0']`,
  'select',
]

const focusElements = [
  ...wrapped.map((w:string)=>`${w}.first-focus`),
  ...wrapped.map((w:string)=>`.first-focus ${w}`),
  "input:not([type='checkbox']), textarea",
  "label[tabindex='0']", 
  "div[tabindex='0']:not(.navi-begin-focus-hidden):not(.navi-end-focus-hidden)", 
  "span[tabindex='0'], .fa[tabindex='0'], a:not(.disabled)",
  "button:not([disabled]):not(.e-modal-close)",
  "button.e-modal-close"
]
const naviInputs = "input:not([disabled]), label[tabindex='0'], div[tabindex='0']:not(.navi-begin-focus-hidden):not(.navi-end-focus-hidden), span[tabindex='0'], .fa[tabindex='0'], textarea, a:not(.disabled), button:not([disabled]):not(.e-modal-close)"

class propsType{
  header?: any;
  footer?: any;
  children?: any;
  role?: string;
  labelledBy?: string;
  describedBy?: string;
  restoreFocus? = true
  modalName?: string;
  style?: any;
  className?: string;
  showClose?=true
  styleHeaderUnderline?: boolean=true
  headerWrapped?=true
  refocus?: string;
  onHide?:any =()=>{};
  breakHide?:any =()=>{};
}
export default forwardRef((props: propsType, ref) => {
  const { header, role, labelledBy, describedBy, restoreFocus = true, modalName,
    footer, style, className, onHide=()=>{}, children, showClose=true, styleHeaderUnderline=true, headerWrapped=true, refocus, breakHide=()=>{}, ...rest } = props
  const [ showing, setShowing ] = useState(false)
  const [ headerLabelId, setHeaderLabelId ] = useState<string>('-1')
  
  const modalRef: any = useRef({})

  const [activeElement, setActiveElement] = useState<any>({})
  const [dndStyle, setDndStyle] = useState({})

  const handleKeypress = (event: any) => {
    if (event.keyCode === Key.ESCAPE) {
      let ddl = document.querySelectorAll(".dropdown-menu.show")   // any dropdown opened
      if (ddl.length > 0) return
      let childModals = (modalRef.current && modalRef.current.querySelectorAll(".e-modal")) || []  // only for top most modal
      if (childModals.length > 0) return
      onClickHide()
    } 
  }
  
  useEffect(() => { setHeaderLabelId(newId())}, [])

  useImperativeHandle(ref, () => ({
    show(){ 
      setTimeout(() => {
        setShowing(true)
        setActiveElement(document.activeElement)
        setDefaultFocus()
        document.addEventListener('keydown', handleKeypress)
        adjustPosition()
      }, 100)// the delay is for restoring focus when it pops from a dropdown menu.
      
    },
    hide(){ 
      handleHide()
    },
    setFirstFocus(){
      setDefaultFocus()
    },
    adjustPosition(){
      adjustPosition()
    }
  }))

  const getNavis = () => Array.from(modalRef.current?.querySelectorAll(naviInputs) || [])
  
  const focusBein = () => {
    const navis: any = getNavis()
    navis[0].focus()
  }
  
  const focusEnd = () => {
    const navis: any = getNavis()
    navis[navis.length-1].focus()
  }

  const setDefaultFocus = () => setTimeout(()=>{
    focusOnSelectors2(modalRef, focusElements)
  }, 100)

  const onFocusNaviEnd = (event: any)=>{
    event.preventDefault()
    focusBein()
  }

  const onFocusNaviBegin = (event: any)=>{    
    event.preventDefault()
    if(modalRef.current?.querySelector('.navi-end-focus-hidden') === event.relatedTarget){
      focusBein()
    }else if(getNavis().some((n:any)=>n ===event.relatedTarget)){
      focusEnd()
    }
  }

  const onClickHide = () => {
    if(breakHide()){
      return
    }
    handleHide()
  }

  const handleHide = () => {
    setShowing(false)
    if(!!refocus){
      let refocusEle: any = document.querySelector(refocus)
      refocusEle && refocusEle.focus()
    } else{    
      restoreFocus && activeElement.focus && activeElement.focus()
    }
    document.removeEventListener('keydown', handleKeypress)    
    onHide()
  }

  const adjustPosition = () => setTimeout(() => {
    if(!modalRef.current){
      return
    }
    
    const rect = modalRef.current.getBoundingClientRect()
    const parentModal: any = findParent(modalRef.current, '.e-modal-dialog')
    const pWidth = parentModal ? parentModal.getBoundingClientRect().width : window.innerWidth
    setDndStyle({left: (pWidth - rect.width)/2 + 'px'})
  })

  loggerInfo('modal.render')
  return <span className={`${showing ? 'e-modal' : 'e-modal-hide'} ${modalName}`}>
    <div className='e-modal-background'></div>
    <Draggable handle='.e-modal-header' bounds='parent'>
      <div tabIndex={0}  ref={modalRef} role={!!role? role: 'dialog'} aria-modal={true} aria-labelledby={labelledBy ? labelledBy: headerLabelId} 
        aria-describedby={describedBy} className={`e-modal-dialog ${className}`} style={{...style, ...dndStyle}} {...rest}>
        <div className={`e-modal-header ${!!header && styleHeaderUnderline ? 'header' : ''}`}>
        {!headerWrapped ? header : 
        <span className='header-text'>
          {header ? <span  role='heading' aria-level={3} id={headerLabelId}>{header}</span> : <span tabIndex={-1} id={headerLabelId}>{'\u00A0'}</span>}
        </span>}

        {!showClose ? <span/> :
        <Button className='e-modal-close' onClick={onClickHide}>
          <img alt='Close Modal' src='./img/icon-close.svg'/>
        </Button>}

        </div>
        <div tabIndex={0} className='navi-begin-focus-hidden' onFocus={onFocusNaviBegin}/>
        {children}
        {footer && <div className='e-modal-footer'>{footer}</div>}
        <div tabIndex={0} className='navi-end-focus-hidden' onFocus={onFocusNaviEnd}/>
      </div>
    </Draggable>
  </span>
})