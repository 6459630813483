import { UserBase, ValueListDto, DataLockDto, DocumentDto, AdUserDto, SortDirection } from 'types'

class ProfileDetailGeneralDto{
    id!: number;
    profId!: number;
    type!: string;
    category!: ValueListDto;
    value!: ValueListDto;
    top!: number; 
}
class ExtResourceWebLinkJson{
    name!: string;
    link!: string;
}
class TalentCardDto{
	//individual
    indId!: number;    //indId or extRsId
    ind!: UserBase;
    manager!: UserBase;
    inMyList!: number; //1: true, 0: false
    ver!: number;
    
    profUUID!: string;  //For Mybatis
    profLastUpdate!: number;
	
	//win
    alumni: number = -1;  //0: current (has win); 1: current (not win); 2: alumni (has active win ass); 3: alumni (no active win ass); 4: external
	//win position in ind.position, ind.ministry
	
	//Details
    details: ProfileDetailGeneralDto[] = [];
	
	//External Resoure Docs
    documents: DocumentDto[] = [];
    links: ExtResourceWebLinkJson[] = [];
}

class RecruiterListCreateDto{
    owner!: UserBase;  //for other list
    ownerPos!: string; //for all lists
    type!: ValueListDto;  //successor, recruiter, custom
    name!: string;
    comment!: string; //description
    
    positionIds: string[] = [];
    labels: ValueListDto[] = [];
    jobLevel!: ValueListDto;
    jobClassification!: ValueListDto;
    ministry!: ValueListDto;
    jobLocation!: ValueListDto;
    supportRemote!: number;
}
class ShareRsListDto{
    id!: number;  //share list entity id
    ver!: number;
    listId!: number;
    shareType!: ValueListDto;
    shareTo!: UserBase;
    comment!: string;
    shareAt!: number;
    shareBy!: UserBase;
    tryToDelete!: number;
}
class RecruiterListLinkedPosDto{
    id!: number; //PK
    posId!: string;
    createAt!: number;
}
export class RecruiterListDto extends RecruiterListCreateDto{
    id!: number;
    ver!: number;
    active: number = 1;
    datalock!: DataLockDto;
    lastUpdateAt!: number;
    ownerMinistry!: string;
    memberNum!: number;
    members: RecruiterListMemberDto[] = [];
	
	//for current access user
    shareType!: ValueListDto;
    shareAt!: number;
    loadedAt!: number;
	
	//for List owner
    shareToList: ShareRsListDto[] = [];
    ownerPosCurrentEmployee: UserBase[] = [];
    linkedPos: RecruiterListLinkedPosDto[] = [];
}
export class SearchResultDto {
    totalRecords!: number;
    items: TalentCardDto[] = [];
}
export class RecruiterListMemberDto{
    id!: number;
    ver!: number;
    active: number = 1;
    removeAt!: number;
    removeBy!: UserBase;
    listId!: number;
    memberDetail!: TalentCardDto;
    comment!: string;
    learningGoalId!: number;
    invite!: string;
    inviteBy!: UserBase;
    inviteAt!: number;
    shortList!: number;
    reminder!: number;
	
	//Manager
    readiness!: ValueListDto;  //2 year
    informType!: ValueListDto;  //immi, ..
    acceptType!: ValueListDto;
    acceptAt!: number;
    respMessage!: string;
    sharedBy!: UserBase;
    createById!: string;
    createBy!: UserBase;
    createAt!: number;
    externalResource!: number;
    addBackAt!: number;
    addBackBy!: UserBase;
    badData!: number;
}

export enum CriteriaTypes{
    TOGGLE = 'toggle',
    RADIO = 'radio',
    TEXT = 'text',
    DROP = 'drop',
    TYPING = 'typing',
    TYPINGDROP = 'typingDrop',
    LOCATION = 'location',
    LANGUAGE = 'language',
}

class ExternalResourceDto{
    id!: number;
    ver!: number;
    active: number = 1;
    
    listId!: number;
    listMemberId!: number;
    listMemberVer!: number;
    
    honorific!: string;
    firstName!: string;
    lastName!: string;
    middleName!: string;
    
    email!: string;
    phone!: string;
    
    position!: string;
    company!: string;
    comment!: string;
    
    opsWinId!: string;
    opsAdId!: string;
    source!: number;      //0: external; 1: ops; 2: bps
	
	opsUser!: AdUserDto;
	documents: DocumentDto[] = [];
    links: ExtResourceWebLinkJson[] = [];
    skills: ValueListDto[] = [];    
    datalock!: DataLockDto;
}
export class ListCreateMemberDto{
    originMemberId!: number;
    indId!: number;
    comment!: string; //description
	readiness!: ValueListDto;
	informType!: ValueListDto;  //immi, ..
	invite!: string;
	
	extId!: number;                 //for clone existing external resource 
    extResource!: ExternalResourceDto; //for new external resource
	opsUser!: AdUserDto;
	listOwnerId!: number; //for init my default list

    duplicated: boolean=false; // UI Only(if existing)
}
export class RecruiterSearchCondition{
    and!: number; //0: Or, 1: And
    conditions: ValueListDto[] = []; //NEXT_ROLE_LOC, WFH value saved under value, 0 / 1
}
enum RecruiterListSortBy{ //MT_SEARCH
    UPDATE_TIME = "ret.prof_last_update",
    NAME = "ret.ind_last_name||', '||ret.ind_first_name",
    FIRST_NAME = "ret.ind_first_name||' '||ret.ind_last_name",
}
export class RecruiterSearchReq{    
    indId!: number;
    currentWorkingListId!: number;
    actAs!: number; //For admin actAs user ind id

    profKeywords!: RecruiterSearchCondition;
	profExtKeywords!: RecruiterSearchCondition;
	profAlumni!: RecruiterSearchCondition;

	// Current Experience
    profIncludePast!: number;
	profJobCategory!: RecruiterSearchCondition;
	profJobLevel!: RecruiterSearchCondition;
	profJobClassification!: RecruiterSearchCondition;
	profJobTitle!: RecruiterSearchCondition;
	profMinistry!: RecruiterSearchCondition;
	profEmployeeGroup!: RecruiterSearchCondition;
	profLocation!: RecruiterSearchCondition; // City only
	profSkill!: RecruiterSearchCondition;
	profLanguage!: RecruiterSearchCondition;
	profLangCertVerbal!: RecruiterSearchCondition;
	profLangCertWritten!: RecruiterSearchCondition;
	profCertificate!: RecruiterSearchCondition;

	// Career interests
	nextRoleJobCategory!: RecruiterSearchCondition;
	nextRoleJobLevel!: RecruiterSearchCondition;
	nextRoleJobClassification!: RecruiterSearchCondition;
	nextRoleEmployeeGroup!: RecruiterSearchCondition;
	nextRoleLocation!: RecruiterSearchCondition;
	nextRoleOpportunityType!: RecruiterSearchCondition;
	nextRoleOpenToOpportunity!: RecruiterSearchCondition;

	// Page configuration
	page!: number; // Start from 0;
	size!: number;
	sortDirection: SortDirection = SortDirection.DESC;
    sortBy: RecruiterListSortBy = RecruiterListSortBy.UPDATE_TIME;
}
export class RecruiterListCreateBySearchDto{
    listDto!: RecruiterListCreateDto;  
    search!: RecruiterSearchReq;
}