import { connect } from 'react-redux'
import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { Modal, Scrollbar, refresh_auto_resize } from 'components/common'
import { getAutoResize } from './selectors'
import './style.scss'

const classNames = {
  modal: 'auto-size-modal',
  modalContainer: 'auto-size-modal-container',
  modalInner: 'auto-size-modal-inner'
}

class propsType{
  children: any;
  modalStep: any;
  className?: string;
  resize: any = 0;
  onHide: any;
  breakHide: any;
  describedBy: any;
  labelledBy: any;
  dispatch: any;
}
const AutoSizeModal = forwardRef((props: propsType, ref: any) => {
  const { children, resize, className, modalStep={}, dispatch, ...rest } = props
  const modalRef: any = useRef({})
  const [visible, setVisible] = useState(false)
  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(false)
      modalRef.current.show()      
      setTimeout(() => {
        refresh_auto_resize(1)
        setTimeout(() => { 
          setVisible(true)
          modalRef.current.setFirstFocus() 
        }, 100)
      }, 200)
    },
    hide: () => modalRef.current.hide(),
    setFirstFocus: () => modalRef.current.setFirstFocus(),
    reset: () => resetView()
  }))
  
  useEffect(()=>{
    setTimeout(() => { modalRef.current?.adjustPosition() })
    const modal: any = document.querySelector(`.${className}.${classNames.modal}.${modalStep.name}`)
    modal.style.width = modalStep?.width
    if(modalStep?.autoSize === false){
      modal.style.height = modalStep?.height || '300px'
      return   
    }
    setTimeout(() => {
      const inner: any = document.querySelector(`.${className} .${classNames.modalContainer} .${classNames.modalInner}`)
      const innerHeight = inner?.scrollHeight + inner?.offsetTop
      // console.log(`modal.scrollHeight: ${modal.scrollHeight}, innerHeight: ${innerHeight}, StepsE[stepName]?.height: ${modalStep?.height}`)
      if(modal.scrollHeight < innerHeight){
        modal.style.height = ((innerHeight || 0) + 35) + "px"
      }else if(innerHeight === 0){
        modal.style.height = modalStep?.height
      }
    }, 100);
    return ()=>{
      modal.style.height = '200px'
    }
  }, [modalStep.name, resize])  

  useEffect(()=>{
    resetView()
  }, [modalStep.name])

  const resetView =() => {
    const inner: any = document.querySelector(`.${className} .${classNames.modalContainer} .${classNames.modalInner}`)
    inner.scrollIntoView()
  }

  return (<Modal ref={modalRef} className={`${!visible ? 'invisible' : ''} ${classNames.modal} ${modalStep.name} ${className}`} {...rest}>
    <div className={classNames.modalContainer}><Scrollbar className='auto-size-modal-scroll-bar'>
      <div className={classNames.modalInner}>{children}</div>
    </Scrollbar></div>
  </Modal>)
})
const mapStateToProps = (state: any, ownProps: any) => {
  return ({
    resize: getAutoResize(state)
  })
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(AutoSizeModal)