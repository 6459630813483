import {
    ADMIN_RATING_LIST_REFRESH,
    ADMIN_RATING_LIST_REPLACE,
    ADMIN_RATING_LIST_SELECT,
    ADMIN_RATING_LIST_SELECT_CLEAR,
    ADMIN_RATING_LIST_CLEAR,
    ADMIN_RATING_DETAIL_REFRESH,
    ADMIN_RATING_SEARCH_CRIT_UPDATE,
    ADMIN_RATING_SEARCH_CRIT_RESET,
    ADMIN_RATING_YEARS_REFRESH,
    ADMIN_RATING_MINISTRIES_REFRESH,
    ADMIN_RATING_MINISTRY_UPDATE,
    ADMIN_RATING_STAGES_VL_REFRESH,
    ADMIN_RATING_STAGES_REFRESH,
    ADMIN_RATING_ALL_STAGES_REFRESH,
    ADMIN_RATING_GOAL_STATUS_REFRESH,
    ADMIN_RATING_CALC_REFRESH,
    ADMIN_RATING_CALC_CLEAR,
    ADMIN_RATING_DETAIL_UPDATE,
    ADMIN_RATING_REMAIN_COUNT_REFRESH,
    ADMIN_RATING_REVIEW_TAB_REFRESH,
    ADMIN_RATING_REVIEW_STEP_REFRESH,
    ADMIN_BARG_REVIEW_REFRESH,
    ADMIN_BARG_UPDATE_REFESH,
    ADMIN_BARG_UPDATE_REVIEW,
    ADMIN_BARG_UPDATE_FINAL_REVIEWS,
    ADMIN_REVIEW_DETAIL_UPDATE
} from 'services/action-types'
import { getRatingValues } from 'utils'

export const initPlanStatus = {id: 0, label: 'All'}
export const initMinistry = {id: 0, label: 'All'}
export const initBargainUnit = {id: 0, label: 'All'}
export const needGoalValues = [
    {id: 0, label: 'All', code: ''},
    {id: 1, label: 'Yes', code: 'RATING'},
    {id: 2, label: 'No', code: 'NO_GOAL'},
]
export const autoPushValues = [
    {id: 0, label: 'Off', code: 'OFF'},
    {id: 1, label: 'On', code: 'ON'},
]

export const initSearchCriteria = {
    planStatus: initPlanStatus,
    ministry: initMinistry,
    planYear: '',
    compGroup: initBargainUnit,
    actAs: false,
    posNum: '',
    name: '',
    mgrName: '',
    page: 0,
    size: 30,
    sortBy: 'NAME',
    sortDirection: 'ASC',
    stages: [],
    existing: {},
    autoPush: null,
    needGoal: null,
    activeAssOnly: false
}

const initialState = {
    items: [], 
    ratingList: {
        items: [], 
        totalRecords: -1,
        totalEmailCount: -1,
        replace: false
    },
    ratingDetails: [],
    checkedItemIds: [],
    goalStatus: [],
    searchCriteria: initSearchCriteria,
    ministries: [],
    years: [],
    reportYears: [],
    stagesVL: [],
    stages: [],
    allStages: [],
    curStage: [],
    calcRate: {},
    ratingUpdate: {},
    currentYear: {},
    remainCount: 0,
    activeTab: 0,
    step: 0,
    bargReview: {},
    reviewUpdate: {}
}

export const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_RATING_LIST_REFRESH:
            return state = {
                ...state,
                items: action.value.data.items,                
                checkedItemIds: [],
                ratingList: {
                    totalRecords: action.value.data.totalRecords,
                    totalEmailCount: action.value.data.totalEmailCount,
                    items: processResult(action.value.data.items, []),
                    replace: false
                },
                searchCriteria: {
                    ...state.searchCriteria,
                    existing: action.value.criteria,
                    stages: state.stages
                },
            }
        case ADMIN_RATING_LIST_REPLACE:
            const _items = replaceList(state.items, action.value)
            return state = {
                ...state,
                items: _items,
                ratingList: {
                    ...state.ratingList, 
                    items: processResult(_items),
                    replace: true
                },
                checkedItemIds: [],
            }
        case ADMIN_RATING_LIST_SELECT:
            return state = {
                ...state,
                ratingList: { ...state.ratingList,
                    items: processSelection(state.ratingList.items, action.value),
                    replace: true
                },
                checkedItemIds: processIdSelection(state.checkedItemIds, action.value)
            }
        case ADMIN_RATING_LIST_SELECT_CLEAR:
            return state = {
                ...state,
                checkedItemIds: []
            }
        case ADMIN_RATING_LIST_CLEAR:
            return state = {
                ...state,
                ratingList: { 
                    items: [], 
                    totalRecords: -1,
                    totalEmailCount: -1,
                    replace: false
                }
            }
                   
        case ADMIN_RATING_DETAIL_REFRESH:
            return state = {
                ...state,
                ratingDetails: action.value
            }
        case ADMIN_RATING_SEARCH_CRIT_UPDATE:
            return state = {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    ...action.value
                }
            }
        case ADMIN_RATING_SEARCH_CRIT_RESET:
            return state = {
                ...state,
                checkedItemIds: []
            }
        case ADMIN_RATING_YEARS_REFRESH:
            let years = action.value.years && action.value.years.length > 0 ? action.value.years: [getDefaultYear()]
            let reportYears = filterReportYears(action.value.years, state.ministry || initMinistry) 
            return state = {
                ...state,
                years: years,
                reportYears: reportYears,
                currentYear: getCurYear(reportYears),
            }
        case ADMIN_RATING_MINISTRIES_REFRESH: 
            return state = {
                ...state,
                ministries: action.value
            }
        case ADMIN_RATING_MINISTRY_UPDATE:
            const yearInfo = getYearInfo(state.years, state.searchCriteria.planYear, action.value)
            return state = {
                ...state,
                reportYears: yearInfo.reportYears,
                // curStage: getCurStage(state.stages, state, action.value),
            }
        case ADMIN_RATING_STAGES_VL_REFRESH: 
            return state = {
                ...state,
                stagesVL: action.value.sort((a, b) => a.displyOrder - b.displayOrder)
            }
        case ADMIN_RATING_STAGES_REFRESH:
            return state = {
                ...state,
                stages: action.value,
                curStage: getCurStage(action.value, state)
            }
        case ADMIN_RATING_ALL_STAGES_REFRESH:
            return state = {
                ...state,
                allStages: action.value
            }
        case ADMIN_RATING_GOAL_STATUS_REFRESH:
            return state = {
                ...state,
                goalStatus: action.value
            }
        case ADMIN_RATING_CALC_CLEAR:
            return state = {
                ...state,
                calcRate: {}
            }
        case ADMIN_RATING_CALC_REFRESH:
            return state = {
                ...state,
                calcRate: {...state.calcRate, [action.value.ratingType]: action.value.calcRate }
            }
        case ADMIN_RATING_DETAIL_UPDATE:
            return state = {
                ...state,
                ratingUpdate: {...state.ratingUpdate, [action.value.ratingType]: action.value.rating }
            }
        case ADMIN_RATING_REMAIN_COUNT_REFRESH:
            return state = {
                ...state,
                remainCount: action.value.remain
            }
        case ADMIN_RATING_REVIEW_TAB_REFRESH:
            return state = {
                ...state,
                activeTab: action.value
            }
        case ADMIN_RATING_REVIEW_STEP_REFRESH:
            return state = {
                ...state,
                step: action.value
            }
        case ADMIN_BARG_REVIEW_REFRESH:
            return state = {
                ...state,
                bargReview: action.value
            }
        case ADMIN_REVIEW_DETAIL_UPDATE:
            return state = {
                ...state,
                reviewUpdate: {...state.reviewUpdate, [action.value.reviewType]: action.value.review }
            }
            case ADMIN_BARG_UPDATE_REFESH:
                return state = {
                    ...state,
                    reviewUpdate: {...state.reviewUpdate, ...action.value}
                }
            case ADMIN_BARG_UPDATE_REVIEW:
                return state = {
                    ...state,
                    reviewUpdate: {...state.reviewUpdate, finalReviews: state.reviewUpdate.finalReviews.map(m=>(m.id === action.value.id? {...m, ...action.value} : m)) }
                }                
        case ADMIN_BARG_UPDATE_FINAL_REVIEWS:
            return state = {
                ...state,
                reviewUpdate: { ...state.reviewUpdate, finalReviews: action.value }
            }
        default:
            return state
    }
}

const processResult = (items, checkItemIds) => {
    let _items = items || []
    let reverseItems = items && items.slice().reverse()
    if (reverseItems) {
        reverseItems.forEach((e, i) =>{
            if (i < reverseItems.length - 1 && e.employee.id === reverseItems[i+1].employee.id) {
                e.employee.indId = e.employee.id
                e.employee.indName = e.employee.userFullName
                e.employee.winId2 = e.employee.winId
                e.employee.id = ''
                e.employee.winId = e.employee.winId === reverseItems[i+1].employee.winId ? '' : e.employee.winId
                e.employee.userFullName = ''
            }
        })
        _items = reverseItems.reverse()
    }
    checkItemIds && _items.map(e => {
        if (checkItemIds.some(t => t===e.planId || t===0)) {
            e.checked = true
        } else {
            e.checked = false
        }
        return e
    })
    return _items
}

export const processReportYears = years => {
    const _years = {}
    years.map(e => _years[e.code] = e.label)
    const result = Object.keys(_years).map(key => ({code: key, label: _years[key]})).sort((a, b) => Number(b.code) - Number(a.code))

    if (result.length === 0) {
        result.push(getDefaultYear())
    }
    return result
}

export const filterReportYears = (years, min) => {
    // if (min.id === initMinistry.id) return processReportYears(years)
    // let result = years.filter(t => Number(t.type) === min.id).map(e => ({code: e.code, label: e.label})).sort((a, b) => Number(b.code) - Number(a.code))
    return processReportYears(years)
}

const getCurStage = (stages, state, ministry) => {
    if (stages.length === 0) {
        return state.stagesVL.find(e => e.code === 'BEFORE_RATE')
    }

    let _ministry = ministry ? ministry : state.searchCriteria.ministry
    const stageValue = e => (state.stagesVL.find(s => e.stageType.code===s.code && e.enable===1) || {value: 0}).value
    if (_ministry?.id === initMinistry.id) {
        // find max index among all
        let maxIdx = Math.max(...stages.map(e => Number(stageValue(e))))
        return state.stagesVL.find(e => Number(e.value) === maxIdx)
    } else {
        // find max index among specific ministry and OPS
        let maxIdx = Math.max(
                ...stages.filter(e => e.ministry.name === 'OPS' || e.ministry.id === (ministry || state.searchCriteria.ministry).id)
                        .map(e => Number(stageValue(e))))
        return state.stagesVL.find(e => Number(e.value) === maxIdx)

    }
}

export const getCurYear = (years, defaultYear) => {
    let curYear = ''
    const ratingCurrentYear = defaultYear || getDefaultYear()
    if ( years && years.length > 0) {
        curYear = years.find(e => e.code === ratingCurrentYear.code) 
        if (!curYear) curYear = years[0]
    }
    return curYear
}

export const getDefaultYear = () => {
    let y = getRatingValues().find(e=>e.code==='CURRENT_YEAR') 
    return !!y ? {code: y.value, label: y.value + '/' + (Number(y.value.substring(2))+1)} : []
}

const processSelection = (items, value) => {
    const _items = [...items]
    if(value.planId === 0){
        _items.map(e=>e.checked=value.checked)
    }else{
        _items.filter(e=>e.planId === value.planId)[0].checked = value.checked
    }
    return _items
}

const processIdSelection = (planIds, value) => {
    let _planIds = [...planIds]
    if (value.checked) {
        _planIds.push(value.planId)
    } else {
        _planIds = _planIds.filter(e => e!==value.planId)
    }
    return _planIds
}

const replaceList = (items, newItems) => {
    newItems.map(n=>{
        const index = items.findIndex(e=>e.planId === n.planId)
        if(index > -1){
            items[index] = n
        }
        return n
    })
    return items
}
export const getYearInfo = (years, planYear, min) =>{
    let newReportYears = filterReportYears(years, min) 
    if (newReportYears.length === 0) {
        newReportYears = [getDefaultYear()]
    }
    let newPlanYear = planYear && newReportYears.find(y => y.code === planYear.code)
    if (!newPlanYear) newPlanYear = newReportYears[0]

    return ({planYear: newPlanYear, reportYears: newReportYears})
}